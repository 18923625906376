<template>
	<v-layout row align-center justify-center>
		<v-flex grow mx-2>
			<v-text-field
				ref="searchInput"
				v-model="searchedName"
				clearable
				:label="$t('actions.search')"
				prepend-icon="search"
				hide-details
				style="padding: 0 !important"
				@focus="onSearchStarted"
				@blur="onSearchEnded"
			/>
		</v-flex>
	</v-layout>
</template>

<script>
import DocumentSearchModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentSearchModuleGuard'
import { mapActions } from "vuex";

export default {
	name: 'FileSearcher',
	mixins: [DocumentSearchModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			searchTimeout: null
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		canSearch: function () {
			return this.model != null && !this.model.is_trash
		},
		searchedName: {
			get: function () {
				return this.$store.state.documents.filter.search.filename
			},
			set: function (newValue) {
				clearTimeout(this.searchTimeout)
				if (!newValue || newValue.trim() == '') {
					this.service.setSearchFilename(null)
				} else {
					const searchedString = newValue
					this.searchTimeout = setTimeout(() => {
						this.service.setSearchFilename(searchedString)
					}, 600)
				}
			}
		}
	},
	methods: {
		...mapActions([
			'setPauseKeyboardListening',
			'setPreventDefaultKeyboardEvents',
		]),
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.BLUR_SEARCH_INPUT, action: this.onBlurSearchInput }]
		},
		isSingleInstanceEventListener: function () {
			return true
		},
		onBlurSearchInput: function () {
			this.$refs.searchInput.blur()
		},
		onSearchStarted: function () {
			this.setPauseKeyboardListening(true)
			this.setPreventDefaultKeyboardEvents(false)
		},
		onSearchEnded: function () {
			this.setPauseKeyboardListening(false)
			this.setPreventDefaultKeyboardEvents(true)
		}
	}
}
</script>
